<template>
  <div class="user-attributes-form">
    <UserAttributeFormInput
      v-for="{ name, isDisabled } in fields"
      :key="name"
      :name="name"
      :disabled="isDisabled"
    />
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import UserAttributeFormInput from './user-attributes-input.vue';
import { USER_ATTRIBUTE } from '@/vue/store/utils/userAttribute';

export default {
  name: 'UserAttributesForm',

  components: {
    UserAttributeFormInput,
  },

  props: {
    isEmailDisabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  computed: {
    fields() {
      return [
        {
          name: USER_ATTRIBUTE.FIRST_NAME,
          isDisabled: false,
        },
        {
          name: USER_ATTRIBUTE.LAST_NAME,
          isDisabled: false,
        },
        {
          name: USER_ATTRIBUTE.EMAIL,
          isDisabled: this.isEmailDisabled,
        },
        {
          name: USER_ATTRIBUTE.VAT_NUMBER,
          isDisabled: false,
        },
        {
          name: USER_ATTRIBUTE.COMPANY_NAME,
          isDisabled: false,
        },
        {
          name: USER_ATTRIBUTE.STREET,
          isDisabled: false,
        },
        {
          name: USER_ATTRIBUTE.CITY,
          isDisabled: false,
        },
        {
          name: USER_ATTRIBUTE.POSTCODE,
          isDisabled: false,
        },
        {
          name: USER_ATTRIBUTE.PHONE_NUMBER,
          isDisabled: false,
        },
      ];
    },
  },

  async created() {
    await this.initUserForm();
  },

  methods: {
    ...mapActions('userAttributesForm', {
      initUserForm: 'init',
    }),
  },
};
</script>
