<template>
  <FormInput
    :key="field.name"
    :value="userFormAttributes[name]"
    :has-error="hasError"
    :error-message="field.errorMessage"
    :label="field.label"
    :name="`user-${field.name}`"
    :type="field.type"
    :disabled="disabled"
    @blur="onBlur"
    @input="onInput"
  />
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import FormInput from './form-input.vue';
import {
  USER_ATTRIBUTE,
  USER_ATTRIBUTES,
} from '@/vue/store/utils/userAttribute';

const fields = [
  {
    name: USER_ATTRIBUTE.FIRST_NAME,
    errorMessage: 'Imię jest wymagane',
    label: 'Imię',
    type: 'text',
  },
  {
    name: USER_ATTRIBUTE.LAST_NAME,
    errorMessage: 'Nazwisko jest wymagane',
    label: 'Nazwisko',
    type: 'text',
  },
  {
    name: USER_ATTRIBUTE.EMAIL,
    errorMessage: 'E-mail jest wymagany',
    label: 'E-mail',
    type: 'email',
  },
  {
    name: USER_ATTRIBUTE.VAT_NUMBER,
    errorMessage: 'NIP jest wymagany',
    label: 'NIP',
    type: 'text',
  },
  {
    name: USER_ATTRIBUTE.COMPANY_NAME,
    errorMessage: 'Nazwa firmy jest wymagana',
    label: 'Nazwa firmy',
    type: 'text',
  },
  {
    name: USER_ATTRIBUTE.STREET,
    errorMessage: 'Ulica jest wymagana',
    label: 'Ulica i numer',
    type: 'text',
  },
  {
    name: USER_ATTRIBUTE.CITY,
    errorMessage: 'Miejscowość jest wymagana',
    label: 'Miejscowość',
    type: 'text',
  },
  {
    name: USER_ATTRIBUTE.POSTCODE,
    errorMessage: 'Kod pocztowy jest wymagany',
    label: 'Kod pocztowy',
    type: 'text',
  },
  {
    name: USER_ATTRIBUTE.PHONE_NUMBER,
    errorMessage: 'Numer telefonu jest wymagany"',
    label: 'Numer telefonu',
    type: 'text',
  },
];

export default {
  name: 'UserAttributesInput',

  components: {
    FormInput,
  },

  props: {
    name: {
      type: String,
      required: true,
      validator(name) {
        return USER_ATTRIBUTES.includes(name);
      },
    },

    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  computed: {
    ...mapGetters('userAttributesForm', {
      userFormAttributes: 'attributes',
      $v: '$v',
    }),

    field() {
      return fields.find(({ name }) => name === this.name);
    },

    hasError() {
      return this.$v[this.name].$error;
    },
  },

  methods: {
    ...mapMutations('userAttributesForm', {
      setUserFormAttribute: 'setAttribute',
      touchUserFormAttribute: 'touch',
    }),

    onBlur() {
      this.touchUserFormAttribute({
        attribute: this.name,
      });
    },

    onInput(value) {
      this.setUserFormAttribute({
        attribute: this.name,
        value,
        shouldTouch: true,
      });
    },
  },
};
</script>
