<template>
  <div
    class="form-input"
    :class="{
      'form-input--no-margin': !hasMargin,
    }"
  >
    <input
      :id="name"
      :name="name"
      :type="type"
      :value="value"
      :disabled="disabled"
      class="input input--text input--full"
      v-on="listeners"
    />
    <label
      :for="name"
      :class="{
        'label--on-top': value !== '',
      }"
      class="label"
    >
      <slot name="label">
        <span class="label__text">{{ label }}</span>
      </slot>
    </label>

    <p v-if="hasError" class="form-input__error">
      <slot name="error">
        {{ errorMessage }}
      </slot>
    </p>
  </div>
</template>

<script>
export default {
  name: 'FormInput',
  props: {
    value: {
      type: [String, Number],
      required: true,
      default: '',
    },
    name: {
      type: String,
      required: true,
    },
    hasError: {
      type: Boolean,
      required: false,
      default: false,
    },
    hasMargin: {
      type: Boolean,
      required: false,
      default: true,
    },
    label: {
      type: String,
      required: false,
      default: 'Label',
    },
    errorMessage: {
      type: String,
      required: false,
      default: 'Error',
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    type: {
      type: String,
      required: false,
      default: 'text',
    },
  },

  computed: {
    listeners() {
      const vm = this;
      return {
        ...this.$listeners,
        input(event) {
          vm.$emit('input', event.target.value);
        },
      };
    },
  },
};
</script>
